import React from 'react';
import { TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, Paper, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function LessonPage() {
    const[lesson, setLesson] = useState([]);
    const [loadingBarProgress,setLoadingBarProgress] = useState('');
    let navigate = useNavigate();



    const Fetchlesson = async () => {
        try {
          const apiUrl = process.env.REACT_APP_BASE_URL;
          setLoadingBarProgress(30);
          const token = sessionStorage.getItem("token");
          const response = await fetch(`${apiUrl}/OfflineLessoon/GetLesson`, {
            method: "POST",
            headers: {
              "content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({}),
          });
    
          if (!response.ok) {
            setLoadingBarProgress(0);
            throw new Error(`Error fetching lesson: ${response.status}`);
          }
      
          const data = await response.json();
      
          if (data.data === null && data.msg === "Record Not Found") {
            setLoadingBarProgress(0);
            console.error('Record Not Found');
            alert('Record Not Found');
            return; // Exit the function if the record is not found
          }
      
          setLoadingBarProgress(100);
          setLesson(data);
        } catch (error) {
          setLoadingBarProgress(0);
          console.error("API request error:", error);
          alert("An error occurred. Please try again later.");
        }
      };

      
      useEffect(() => {
        Fetchlesson();
      },[]);

    const handleEditLesson = (lessonId) => {
      navigate(`/updatelesson/${lessonId}`)
    };

    const handleAdd = () =>{
        navigate('/addlession');
    }

    const handleDeleteLesson = (lessonId) => {
        // Confirm before deleting
        if (window.confirm('Are you sure you want to delete this lesson?')) {
            // Remove the lesson from the state or call an API to delete it
            setLesson((prevLessons) =>
                prevLessons.filter((lesson) => lesson.lessonId !== lessonId)
            );
        }
    };

    return (
        <div>

            <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Lesson Details
                </Typography>
                <div style={{ marginBottom: '20px' }}>
                    <TextField
                        label="Search Lesson"
                        variant="outlined"
                    // Add search functionality here
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: '10px', marginTop: '5px' }}
                        onClick={handleAdd}
                    // Add functionality to add a new lesson
                    >
                        Add Lesson
                    </Button>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Lesson Name</TableCell>
                            <TableCell>Class</TableCell>
                            <TableCell>Content</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lesson.map((lesson) => (
                            <TableRow key={lesson.lessonId}>
                                <TableCell>{lesson.lessonName}</TableCell>
                                <TableCell>{lesson.className}</TableCell>
                                <TableCell>{lesson.lessonContent}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        onClick={() => handleEditLesson(lesson.lessonId)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                    variant="contained"
                    color="error"
                    style={{ marginLeft: '5px' }}
                    onClick={() => handleDeleteLesson(lesson.lessonId)}
                >
                    Delete
                </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
}

export default LessonPage;